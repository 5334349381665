@import url("https://fonts.cdnfonts.com/css/poppins");
.section_title_style {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;

  color: #393e43;
}


.type_name_3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
}
@media (max-width: 992px) {

  .type_name_3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
  }
}
.check_input {
  width: 60px !important;
  height: 35px !important;
  /* background-color: #fff !important; */
  border-radius: 41px !important;
}

.bios_container {
  background: #fcfcfc;
  border: 1px solid #f3f3f3;
  border-radius: 8px;
  padding: 10px;
  font-size: 14px;
  color: #838aa3;

  /* width: 790px; */
}

.bios_height {
  height: 234px;
}

.input_text {
  background: #fcfcfc;
  border: 1px solid #f3f3f3;
  border-radius: 8px;
  height: 35px;
  padding: 2% 1.5%;
  padding: 3% 2.5%;
}

.input_text::placeholder {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #838aa3;
}

.add_more_btn {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 150%;
  padding: 1.5% 3% !important;
  border: 1px solid #ffb703;
  border-radius: 8px !important;
  min-height: 40px;
}

.w-80 {
  width: 90% !important;
}
