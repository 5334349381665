.contact_us_width {
  background-color: white !important;
  padding: 10% 15% 5% 15% !important;
}

.opt-style-con{
  background-color: white;
  color: #000;
  padding:0.5rem 0 ;
  height: 30px;
}
@media (max-width: 992px) {
  .contact_us_width {
    padding: 10% 5% 5% 5% !important;

  }
  
}