
.container2 {
  display: flex;
    flex-direction: column;
    align-items: center;
}
.container-p {
  display: flex;
  height: 70vh; /* Set container height to fit the page height */
  justify-content: space-between; /* Add space between sections */
  padding: 20px; /* Add padding around the container */
}
.container-p2 {
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 20px;
  width: 70%;
  overflow: auto; /* Add overflow property */
}


.section-p {

  flex: 1;
  border: 2px solid #023047; /* Initial border color */
  cursor: pointer;
  margin: 10px; /* Add margin between sections */
  padding: 10px; /* Add padding inside sections */
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3); /* Add shadow to sections */
  border-radius: 5px; /* Add border radius */
  text-align: center; /* Center-align text */
  font-family: 'White Dream PERSONAL USE ONLY';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 55px;
  color: #FFB703;
  transition: border 0.3s, filter 0.3s, transform 0.3s; /* Add transition effect */
  position: relative; /* Make sections relative for absolute positioning */
}

.section-p:hover {
  border-color: #FFB703; /* Yellow border color on hover */
}

.selected-p {
  border-color: #FFB703; /* Yellow border color when selected */
  box-shadow: 0px 10px 20px rgba(255, 183, 3, 0.5); /* Move shadow down when selected */
}

.ul-p {
  list-style: none;
  padding: 0;
  position: absolute; /* Position ul absolutely */
  top: 55%; /* Align ul to the vertical center of section */
  left: 40%; /* Align ul to the horizontal center of section */
  transform: translate(-50%, -50%); /* Center ul */
}

.service-list-drop-down-p {
  font-family: 'Gotham';
  font-style: italic;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  margin-top: 13px;
  width: 150%;
  margin-top: 40px;
  display: flex;
  color: #023047;
}

.landing-page-success-text-p {
  font-family: 'White Dream PERSONAL USE ONLY';
  font-style: normal;
  font-weight: 400;
  font-size: 52px;
  line-height: 70px;
  margin-top: 20px;
  text-align: center;
  color: #FFB703;
}

.landing-page-success-text-desc-popings-p {
  font-family: 'Gotham';
  font-style: italic;
  font-weight: 500;
  font-size: 22px;
  line-height: 20px;
  color: #023047;
  margin-top: 20px;
  text-align: center;
}

.btn-group-p {
  display: flex;
  justify-content: center; /* Center the buttons horizontally */
  margin-top: -10px;
}

.btn-previous-step,
.btn-next-step {
  width: 200px; /* Set a fixed width for both buttons */
  padding: 10px 20px; /* Add padding to the buttons */
  font-size: 25px; /* Adjust font size */
  background-color: #023047; /* Button background color */
  color: #FFFFFF; /* Button text color */
  border: none; /* Remove button border */
  border-radius: 5px; /* Add border radius */
  font-family: 'Gotham';
  text-decoration: none; /* Remove default link decoration */
  transition: background-color 0.3s; /* Add transition effect */
  display: flex;
  align-items: center; /* Center the content vertically */
  justify-content: center; /* Center the content horizontally */
}

.btn-group-p 
{ margin-bottom: 40px;margin-left: 10px;}
.btn-next-step {
  margin-left: 10px; /* Add some space between buttons */
 
}

.btn-previous-step:hover,
.btn-next-step:hover {
  background-color: #FFB703; /* Change background color on hover */
}
.price-p {

  margin-left: 20px;
  color: #FFB703;
}




.receipt-table-p table {
  width: 80%;
  margin: 0 auto;
  border-collapse: collapse;
}

.receipt-table-p .label {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 0.3rem;
  text-align: right;
  border-bottom: 1px solid #ccc;
}

.receipt-table-p .value {
  font-size: 1.5rem;
  padding: 0.3rem;
  border-bottom: 1px solid #ccc;

}

