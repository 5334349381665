.profile_card {
  margin: 10% 5% 8% 5%;
  background: #fff;
  border-radius: 8px;
  min-height: 60vh;
}
.corner_picture {
  height: 120px;
  width: 120px;
  object-fit: cover;
}
.corner_picture2 {
  height: auto;
  width: 180px;
  transform: rotate(180deg);
}
.card_p_container {
  padding: 5% 0% 3% 3%;
}

.profile_card_p {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #023047;
}

.profile_btn_p {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
}

.user_picture_container {
  margin-left: 2%;

  background-image: url("../images/Ellipse\ 3.webp");
  background-position: center;
  background-size: 80%;
  background-repeat: no-repeat;
  padding: 2.5rem;

  /* max-width: 150px; */
}

.user_picture_border {
  border: 2px solid white;
  border-radius: 50%;
}

.user_name {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 119.5%;
  color: #0e243a;
}

.user_email_position {
  margin-left: -5px;
}

.user_email {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #7c8087;
}
