.btn-outline-danger:hover {
    background-color: transparent !important; /* Change the background color to transparent when hovering */
  }
  .btn-outline-primary:hover {
    background-color: transparent !important; /* Change the background color to transparent when hovering */
  }
  .button {
    background-color: rgba(255, 184, 3, 0.162);
    color: hsl(44, 100%, 50%);
    border: none;
    border-radius: 10px;
    padding: 10px 80px; /* Make the button bigger */
    font-weight: bold;
    font-size: 16px;
    cursor: pointer; /* Change cursor to pointer on hover */
    outline: none; /* Remove default button outline */
    transition: background-color 0.3s, color 0.3s; /* Add transition effect */
  }
  
  .button:hover {
    background-color: hsl(44, 100%, 50%);;
    color: white; /* Change text color on hover */
  }
  