.test-icon {
    font-size: 8rem;
    margin-bottom: 20px;
}

.test-icon.processing {
    color: #f4ba41ff; /* Yellow color for processing */
}

.test-icon.success {
    color: yellow; /* Yellow color for success */
}

.test-icon.failure {
    color: red; /* Red color for failure */
}
