.coming-soon-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('../../assets/images/belgique.webp') center/cover no-repeat;;

}

.coming-soon-content h1 {
  font-size: 5rem;
  color: #fff;
}

.coming-soon-content p {
    padding-top: 5%;
  font-size: 3rem;
  color: #fff;
}