@import"../../assets/scss/media-queries.scss";
// @include lt-xxl {}

.main {
  width: 100%;
  min-height: 100vh;

  display: flex;
  align-items: stretch;
  justify-content: space-between;

  @include lt-md {
    height: auto;
    flex-direction: column;
  }
}

.img_side {
  width: 50%;
  height: auto;

  @include lt-xl {
    height: auto;
  }

  @include lt-md {
    width: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}

.form_side {
  width: 50%;
  padding: 50px 60px;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  @include lt-xl {
    padding: 30px 30px;
  }
  @include lt-lg {
    padding: 20px 40px;
  }
  @include lt-md {
    width: 100%;
    padding: 30px 20px;
  }

  h3 {
    @include lt-md {
      width: 100%;
      text-align: center;
    }
  }
}
