@import url("https://fonts.cdnfonts.com/css/gotham");

ul li a {
  text-decoration: none !important;
  color: white;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  font-family: Gotham;
}
ul li {
  text-decoration: none !important;
  color: white;
  text-align: left;
  /* padding-bottom: 1rem; */
}
ul {
  text-decoration: none !important;
  color: white;
  text-align: left;
  list-style-type: none;
}

.text-footer {
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  font-family: Gotham;
}
.footer_container {
  padding: 3% 10% 2% 4%;
}

.footer_title {
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  text-align: left;
  padding-left: 2rem;
}

.footer_title-contact {
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  text-align: left;
}
.contact-mail {
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  font-family: Gotham;
  color: #ffb703;
}
.icon-img {
  margin-left: 0.5rem;
  color: #ffb703;
  padding: 0;

  border: transparent;
}
.multilang-btn {
  color: white !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: Gotham !important;
}
@media (max-width: 992px) {
  .footer_container {
    padding: 0% 10% 0% 4%;
}
.footer_title {
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  text-align: left;
  padding-left: 0rem;
}
}