.help_center_container {
  background-position: center !important;
  background-size: cover !important;
  height: 80vh;
  position: relative; /* Ensure the container is positioned relatively */

  display: flex;
  align-items: center;
  justify-content: center;
}



.help_center_title {
  font-style: normal;
  font-weight: 400;
  font-size: 100px;
}
.help_center_sub_title {
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 40px;
  /* or 167% */
  text-align: center;
}
.faq_section {
  padding: 2% 5% 3% 5% !important;
}
.faq_title {
  font-style: normal;
  font-weight: 400;
  font-size: 38px;
  line-height: 34px;
  margin-top: 3%;
  margin-bottom: 7%;
}
.drop_down_title {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 30px !important;
  color: rgba(2, 48, 71, 0.64) !important;
}
.drop_down_title_border {
  border-bottom: 0.5px solid rgba(2, 48, 71, 0.22) !important;
}
.dropdown-item {
  word-wrap: break-word;
}

.dropdown-toggle {
  border-bottom: 0.5px solid rgba(2, 48, 71, 0.22);
  color: black;
}

.dropdown-toggle:hover {
  background-color: white;
  border-bottom-color: blue;
  color: blue;
}

.help_center_active_drop_down {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
}
.line_height_150 {
  line-height: 25px;
}

.help_center_active_drop_down_li {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #023047;
  line-height: 150% !important;
}
.btn-elipss-css{
  overflow: hidden;
  text-overflow: ellipsis;
  /* Allows the text to break if it is too long for the button */
  white-space: nowrap;
}
@media (max-width: 992px) {
  .faq_title {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 30px !important;
    line-height:175% !important;
    display: inline-block !important;
    transition: transform 0.5s !important;
  }
  .faq_section {
    padding: 10% 5% 10% 5% !important;
  }
  .help_center_title{
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
  }
  .help_center_sub_title {
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
}
}
