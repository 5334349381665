@import url("https://fonts.cdnfonts.com/css/white-dream-personal-use-only");
@import url("https://fonts.cdnfonts.com/css/gotham");
@import url("https://fonts.cdnfonts.com/css/nunito");
@import url("https://fonts.cdnfonts.com/css/poppins");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* list-style-type: none; */
  outline: none;
  text-decoration: none !important;
  text-decoration-color: white;
  font-family: "Work Sans", sans-serif;
}
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
.f-12{
  font-size: 12px;
}
.scrollin{
  max-height: 400px;
  overflow-y: scroll;
}
body {
  /* background-color: #f5f5f5; */
  overflow-x: hidden;
}
.custom-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.typo-sec{
  font-family: 'Gotham'!important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  font-weight: 400;
}
.text-over{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 992px) {
  .typo-sec{
    font-family: 'Gotham'!important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    font-weight: 400;
  }
}
@media (min-width: 1600px) {
  .custom-container {
    max-width: 85%;
  }
}
@media (min-width: 1850px) {
  .custom-container {
    max-width: 85%;
  }
}
@media (min-width: 768px) {
  .custom-container {
    max-width: 85%;
  }
}
@media (min-width: 992px) {
  .custom-container {
    max-width: 85%;
  }
}
@media (min-width: 1200px) {
  .custom-container {
    max-width: 85%;
  }
}
.color_main {
  color: #023047 !important;
}
.color_second {
  color: #ffb703 !important;
}
.color_third {
  color: #fb8500;
}
.color_forth {
  color: #219ebc;
}

.color_grey {
  color: #aaaaaa;
}

.underline_color_main {
  border-bottom: 0.5px solid #023047 !important;
  display: inline-block;
}

.background_grey {
  background-color: #fff;
}

.background_main {
  background-color: #023047;
}
.background_second {
  background-color: #ffb703;
}
.background_third {
  background-color: #fb8500;
}
.background_forth {
  background-color: #219ebc;
}

.main_btn {
  background: #ffb703 !important;
  color: white !important;
  padding: 10px 7% !important;
  border-radius: 12px;
  height: 45px;
  font-size: 16px;
}

.countries_btn {
  background: #ffb703 !important;
  color: white !important;
  padding: 10px 7% !important;
  border-radius: 12px;
  height: 45px;
  font-size: 16px;
  margin-bottom: 20px;
}
.c_explore_btn {
  background: #ffb703 !important;
  color: white !important;
  padding: 10px 7% !important;
  border-radius: 12px;
  height: 45px;
  font-size: 16px;
}

.img-thumbnail{
  border: none!important;
  padding: none!important;
}
.main_btn:hover {
  color: #ffb703;
  border: 1px solid #ffb703;
}

.main_btn_outline {
  color: #ffb703;
  border: 1px solid #ffb703;
}

.main_btn_outline:hover {
  background: #ffb703;
  color: white;
}
@media (max-width: 992px) {
  .main_btn {
    background: #ffb703 !important;
    color: white !important;
    padding: 10px 7% !important;
    border-radius: 12px;
    height: 45px;
    font-size: 14px!important;
  }
  
}
.title_main {
  font-family: "White Dream PERSONAL USE ONLY", sans-serif !important;
}

.title_second {
  font-family: "Gotham", sans-serif !important;
}

.title_3rd {
  font-family: "Nunito", sans-serif !important;
}

.title_forth {
  font-family: "Poppins", sans-serif !important;
}

.typo_1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
}

.typo_2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
}

.typo_inside_img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.p_x_large {
  padding: 0 15%;
}

.input_group_merge {
  border: none !important;
}

.submit_btn {
  border-radius: 12px;
  min-height: 56px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
}

.box-card {
  height: 64px;
  width: 64px;
  background: #023047;
  position: fixed;
  bottom: 5%;
  right: 5%;
  border-radius: 50%;
  border: 1px solid white;
  z-index: 9999;
}
.box-messanger-user {
  position: fixed;
  bottom: 15%;
  right: 5%;
  border-radius: 8px;
  height: 530px;
  width: 350px;
  z-index: 2;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 9px;
}
.Bonjour-section-box {
  background-color: #023047;
  border-radius: 8px;
  padding: 1.5rem
  ;
  color: white;
}
.user-message-box-hi {
  padding: 1.5rem 0.5rem;
  /* color: #023047; */
  font-size: 14px;
  height: 68%;
  overflow-y: scroll;
}
.input-send-support-text {
  height: 100%;
  width: 100%;
  border-radius: 0 0 5px 5px;
  border: 1px solid #c2c2c2;
  padding: 0 0.75rem;
  font-size: 14px;
}
.msg-hi-support-owner,
.msg-hi-support {
  width: 100%;
}
.msg-hi-support-owner-pre {
  background-color: #ffb80376;
  padding: 0.75rem;
  color: #023047;
  width: 100%;
  margin-left: 0.75rem;
  border-radius: 8px;
}

.msg-hi-support-owner-pre-2 {
  background-color:#EEEEEE;
  padding: 0.75rem;
  color: #023047;
  width: 100%;
  margin-left: 0.75rem;
  border-radius: 8px;
}
.msg-hi-support-pre {
  background-color: #02304758;
  padding: 0.3rem;
  color: #ffb803;
  border-radius: 5px;
  width: 100%;
}
.img-box-raduis-border img {
  height: 48px;
  width: 48px;
}
.text-pos-hi-box {
  font-size: 22px;
  margin: 0;
  margin-right: 1rem;
}

.dialog-btn {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  margin-left: 100px;
}


.avatar-big {
  background-color: #6c757d;
  border-radius: 500px;
  height: 140px;
  width: 140px;
  margin: 0px 0px 2px 2px;
  color: white;
  font-size: 60px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

:root {
  --main-ccc: #ffb703;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--main-ccc);
}

