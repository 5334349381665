
.test-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.test-content {
    text-align: center;
}

.test-icon {
    font-size: 8rem;
    color: green;
}

.test-heading {
    margin-top: 1rem;
    font-size: 5rem;
}

.test-message {
    margin-top: 1rem;
    font-size: 3rem;
}



.receipt-table table {
    width: 80%;
    margin: 0 auto;
    border-collapse: collapse;
}

.receipt-table .label {
    font-size: 2rem;
    font-weight: bold;
    padding: 0.5rem;
    text-align: right;
    border-bottom: 1px solid #ccc;
}

.receipt-table .value {
    font-size: 2rem;
    padding: 0.5rem;
    border-bottom: 1px solid #ccc;

}

.retry-button {
    padding: 15px 30px; /* Increase padding to make the button bigger */
    font-size: 2rem; /* Increase font size */
    background-color: #f4ba41ff; /* Set button color */
    color: black; /* Text color */
    border: none;
    font-weight:bold;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.retry-button:hover {
    background-color: #d29b37ff; /* Button color on hover */
}


.test-icon.failure {
    font-size: 10rem;
    color: red; /* Set icon color to red */
    margin-bottom: 20px;
}
